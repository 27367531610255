import React, { useState } from 'react';
import { css } from '@emotion/react';
import Cross from '../svg/cross.svg';
import ConditionalWrapper from './conditional-wrapper';
import Link from './link';

export default function Pill({
  className,
  size,
  url,
  text,
  active,
  activeAlt,
  hover,
  invert,
  transparent,
  number,
  cross,
  dropShadow,
  onMouseEnter,
  onMouseOut,
  onClick,
}) {
  const sizes = [
    {
      typeClass: 'type--body-small',
      padding: '0.2rem 0.8rem 0.2rem 0.9rem',
      borderWidth: '3px',
    }, // Size 0
    {
      typeClass: 'type--button-small',
      padding: '0.7rem 1.1rem 0.3rem',
      borderWidth: '3px',
    }, // Size 1
    {
      typeClass: 'type--button',
      padding: '0.8rem 1.4rem 0.6rem 1.3rem',
      borderWidth: '3px',
    }, // Size 2
    {
      typeClass: 'type--button-large',
      padding: '1.2rem 2.6rem 1rem',
      borderWidth: '4px',
    }, // Size 3
    {
      typeClass: 'type--button-xlarge',
      padding: '1.6rem 3.5rem 1.2rem',
      borderWidth: '5px',
    }, // Size 4
  ];

  const sizesTablet = [
    {
      typeClass: 'type--body-small',
      padding: '0.2rem 0.8rem 0.2rem 0.9rem',
      borderWidth: '3px',
    }, // Size 0
    {
      typeClass: 'type--button-small',
      padding: '0.7rem 1.1rem 0.3rem',
      borderWidth: '3px',
    }, // Size 1
    {
      typeClass: 'type--button',
      padding: '0.8rem 1.4rem 0.6rem 1.3rem',
      borderWidth: '3px',
    }, // Size 2
    {
      typeClass: 'type--button-large',
      padding: '0.8rem 1.2rem 0.6rem 1.3rem',
      borderWidth: '3px',
    }, // Size 3
    {
      typeClass: 'type--button-xlarge',
      padding: '1.6rem 3.6rem 1.4rem',
      borderWidth: '4px',
    }, // Size 4 tablet
  ];

  const sizesMobile = [
    {
      typeClass: 'type--body-small',
      padding: '0.1rem 0.6rem 0.1rem 0.7rem',
      borderWidth: '3px',
    }, // Size 0
    {
      typeClass: 'type--button-small',
      padding: '0.7rem 1.1rem 0.3rem',
      borderWidth: '3px',
    }, // Size 1
    {
      typeClass: 'type--button',
      padding: '0.8rem 1.4rem 0.6rem 1.3rem',
      borderWidth: '3px',
    }, // Size 2
    {
      typeClass: 'type--button-large',
      padding: '0.8rem 1.2rem 0.6rem 1.3rem',
      borderWidth: '3px',
    }, // Size 3
    {
      typeClass: 'type--button-xlarge',
      padding: '1.2rem 1.7rem 1rem',
      borderWidth: '3px',
    }, // Size 4 mobile
  ];

  const black = invert ? 'var(--white)' : 'var(--black)';
  const white = invert ? 'var(--black)' : 'var(--white)';
  const hoverForeground = hover || url ? (typeof hover === 'string' ? black : white) : black;
  const hoverBackground =
    hover || url
      ? typeof hover === 'string'
        ? hover
        : black
      : transparent
      ? 'transparent'
      : white;
  const activeStyle = `
    color: ${white};
    background-color: ${black};

    &:hover {
      color: ${hover || url ? black : white};
      background-color: ${hover || url ? (typeof hover === 'string' ? hover : white) : black};

      svg path {
        fill: ${(hover || url) && black};
      }
    }
  `;

  const activeAltStyle = `
    color: ${black};
    background-color: ${white};

    &:hover {
      color: ${hover || url ? white : black};
      background-color: ${hover || url ? black : white};

      svg path {
        fill: ${(hover || url) && black};
        };
      }
    }
  `;

  return (
    <ConditionalWrapper
      condition={url}
      wrapper={(children) => (
        <Link
          className={`type--underline-none ${className ? className : ''}`}
          to={url}
          css={css`
            display: inline-block;
            text-decoration: none;
          `}
        >
          {children}
        </Link>
      )}
    >
      <div
        className={`${sizes[size].typeClass} ${className && !url ? className : ''}`}
        css={css`
          display: inline-block;
          color: ${black};
          white-space: nowrap;
          padding: ${sizes[size].padding};
          background-color: ${transparent ? 'transparent' : white};
          border: ${sizes[size].borderWidth} solid ${black};
          border-radius: 44rem;
          filter: ${(dropShadow) ? 'var(--dropShadow)' : 'none'};
          cursor: ${hover || url ? 'pointer' : 'auto'};

          &:hover {
            color: ${hoverForeground};
            background-color: ${hoverBackground};

            svg path {
              fill: ${(hover || url) && white};
            }
          }

          @media (max-width: 800px) {
            padding: ${sizesTablet[size].padding};
            border-width: ${sizesTablet[size].borderWidth};
          }

          @media (max-width: 550px) {
            padding: ${sizesMobile[size].padding};
            border-width: ${sizesMobile[size].borderWidth};
          }

          ${active && activeStyle}
          ${activeAlt && activeAltStyle}
        `}
        onMouseEnter={onMouseEnter}
        onMouseOut={onMouseOut}
        onClick={onClick}
      >
        {text}
        {typeof number === 'number' && (
          <sup
            className="type--mono-heading"
            css={css`
              display: inline;
              top: -0.2rem;
              margin-left: 0.7rem;
              pointer-events: none;
            `}
          >
            {number}
          </sup>
        )}
        {cross && (
          <Cross
            css={css`
              margin-bottom: -0.2rem;
              margin-left: 1.1rem;

              path {
                fill: ${active ? white : black};
              }
            `}
          />
        )}
      </div>
    </ConditionalWrapper>
  );
}
