import React, { useState } from 'react';
import { css } from '@emotion/react';
import { randomColor, randomGoodColor } from './colors';

export default function Form({ className, title, subtitle, fields, buttonText, onSubmit, submitErrorMessage }) {
  const [formData, setFormData] = useState(fields.map((field) => ''));
  const [formValidBackground, setFormValidBackground] = useState(false);
  const [invalidInputsRed, setInvalidInputsRed] = useState(false);
  const [displayErrorMessage, setDisplayErrorMessage] = useState(false);
  const [color, setColor] = useState(randomGoodColor());

  function handleInputChange(e, i) {
    let data = { ...formData };
    data[i] = e.target.value;
    setFormData(data);
  }

  function handleFocus() {
    setColor(randomGoodColor());
  }

  function handleFormChange(e) {
    const form = e.target.form;

    if (form.checkValidity()) {
      !formValidBackground && setFormValidBackground(randomGoodColor());
    } else {
      setFormValidBackground(false);
    }
  }

  function handleFormSubmit(e) {
    e.preventDefault();

    const form = e.target;

    if (!form.reportValidity()) {
      setInvalidInputsRed(true);
      setDisplayErrorMessage(true);
    } else {
      onSubmit(e, new FormData(form));
    }
  }

  return (
    <div
      className={className}
      css={css`
        background-color: ${formValidBackground ? formValidBackground : 'transparent'};
      `}
    >
      <h3
        css={css`
          padding-bottom: var(--margin5);
          border-bottom: 4px solid var(--black);
          margin-bottom: var(--margin6);

          @media (max-width: 800px) {
            padding-bottom: var(--gutter);
            margin-bottom: var(--margin3);
          }

          @media (max-width: 550px) {
            padding-bottom: 2.3rem;
            margin-bottom: var(--gutter);
          }
        `}
      >
        {title}
      </h3>
      <h4
        css={css`
          margin-bottom: var(--margin8);

          @media (max-width: 800px) {
            margin-bottom: var(--margin6);
          }

          @media (max-width: 550px) {
            margin-bottom: var(--margin3);
          }
        `}
      >
        {subtitle}
      </h4>
      <form
        noValidate
        onSubmit={handleFormSubmit}
        onChange={handleFormChange}
        css={css`
          text-align: center;
        `}
      >
        <div
          css={css`
            display: grid;
            grid-template-columns: repeat(12, 1fr);
            column-gap: var(--gutter);
            margin-bottom: var(--margin3);

            @media (max-width: 800px) {
              grid-template-columns: repeat(8, 1fr);
              column-gap: var(--gutterHalf);
              margin-bottom: var(--margin6);
            }

            @media (max-width: 600px) {
              display: block;
            }

            @media (max-width: 550px) {
              margin-bottom: 3.5rem;
            }

            input[type='text'],
            input[type='email'],
            input[type='tel'],
            textarea {
              grid-column-start: span 6;
              color: ${invalidInputsRed ? 'var(--red)' : 'var(--black)'};
              border-color: ${invalidInputsRed ? 'var(--red)' : 'var(--gray-light)'};
              margin-bottom: 3.3rem;

              @media (max-width: 800px) {
                grid-column-start: span 4;
              }

              @media (max-width: 600px) {
                width: 100%;
                margin-bottom: var(--gutterHalf);
              }

              &::placeholder {
                color: ${invalidInputsRed ? 'var(--red)' : 'var(--black)'};
              }

              &:valid {
                color: var(--black);
                border-color: var(--black);

                &::placeholder {
                  color: var(--black);
                }

                &:placeholder-shown {
                  border-color: var(--gray-light);
                }
              }

              &:focus,
              &:focus:placeholder-shown {
                color: ${formValidBackground ? 'var(--black)' : color};
                border-color: ${formValidBackground ? 'var(--black)' : color};

                &::placeholder {
                  color: var(--gray-light);
                }
              }
            }

            textarea {
              grid-column-start: span 12;
              resize: none;

              @media (max-width: 800px) {
                grid-column-start: span 8;
              }

              @media (max-width: 600px) {
                width: 100%;
              }
            }
          `}
        >
          {fields?.map((field, i) => {
            if (field.type === 'textarea') {
              return <textarea
                key={field.name}
                className="type--heading-four"
                name={field.name}
                placeholder={field.placeholder}
                value={formData.name}
                onFocus={handleFocus}
                onChange={(e) => handleInputChange(e, i)}
                required={field.required}
                rows={4}
              ></textarea>
            } else {
              return (
                <input
                  key={field.name}
                  className="type--heading-four"
                  type={field.type}
                  name={field.name}
                  placeholder={field.placeholder}
                  value={formData.name}
                  onFocus={handleFocus}
                  onChange={(e) => handleInputChange(e, i)}
                  required={field.required}
                />
              );
            }
          })}
        </div>
        <div
          className="type--paragraph"
          css={css`
            display: ${displayErrorMessage ? 'block' : 'none'};
            color: var(--red);
            margin-bottom: var(--margin3);
          `}
        >
          {submitErrorMessage}
        </div>
        <input
          className="type--paragraph"
          type="submit"
          value={(buttonText) ? buttonText : 'Submit'}
          css={css`
            display: inline-block;
            font-weight: normal;
            color: var(--black);
            padding: 1.2rem 2.6rem 1rem;
            background-color: transparent;
            border: 4px solid var(--black);
            border-radius: 44rem;
            margin-top: 2.3rem;
            cursor: pointer;

            &:hover {
              color: var(--white);
              background-color: var(--black);
            }
          `}
        />
      </form>
    </div>
  );
}
